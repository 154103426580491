
import { Component, Vue } from "vue-property-decorator";
import {
  JobsApi,
  InvoiceDraft,
  InvoiceDraftApi,
  InvoiceCustomerDrafts,
  PayoutApi,
  GetInvoiceDraftsRequest,
  GetInvoiceDraftsStateEnum,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import InvoiceDraftListCustomers from "@/components/invoice/InvoiceDraftListCustomers.vue";
import Pagination from "@/components/shared/Pagination.vue";
import ElementList from "@/components/shared/layout/ElementList.vue";

@Component({
  components: {
    Pagination,
    ElementList,
    InvoiceDraftListCustomers,
  },
})
export default class Invoices extends Vue {
  invoiceDrafts?: InvoiceDraft[] | null = [];
  customerInvoiceDrafts?: InvoiceCustomerDrafts[] | null = [];
  searchString = "";
  sendingToEconomics = false;
  sendToEconomicsResponse = false;
  sendToEconomicsText = "Send to e-conomics";
  getInvoiceDraftsRequest: GetInvoiceDraftsRequest = {
    state: "NEW" as GetInvoiceDraftsStateEnum,
  };

  invoiceDraftsLoading = true;
  invoiceDraftsLoaded = false;
  invoiceDraftsErrored = false;

  statsCustomers = 0;
  statsExpectedInvoiced = 0;
  statsInvoices = 0;
  statsInvoicesMedian = 0;
  statsInvoicesTotal = 0;
  statsKreditnotas = 0;
  statsKreditnotasMedian = 0;
  statsKreditnotasTotal = 0;

  chosenYear = -1;
  chosenMonth = -1;
  chosenJobType = "ALL";

  years = {
    2018: 2018,
    2019: 2019,
    2020: 2020,
    2021: 2021,
    2022: 2022,
    2023: 2023,
    2024: 2024
  };

  months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "Marts" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  get getCustomerInvoiceDrafts() {
    if (this.customerInvoiceDrafts && this.getSearchString) {
      return this.customerInvoiceDrafts.filter(
        (customer: any) =>
          (customer.businessCode &&
            customer.businessCode.includes(this.getSearchString)) ||
          customer.invoiceCustomerName
            ?.toLowerCase()
            .includes(this.getSearchString.toLowerCase())
      );
    }
    return this.customerInvoiceDrafts;
  }

  set getCustomerInvoiceDrafts(value) {
    this.customerInvoiceDrafts = value;
  }

  get getChosenYear() {
    if (this.chosenYear === -1) {
      this.getChosenYear = new Date().getFullYear();
    }
    return this.chosenYear;
  }

  set getChosenYear(value) {
    this.chosenYear = value;
  }

  get getChosenMonth() {
    if (this.chosenMonth === -1) {
      this.getChosenMonth = new Date().getMonth() + 1;
    }
    return this.chosenMonth;
  }

  set getChosenMonth(value) {
    this.chosenMonth = value;
  }

  get getSearchString() {
    return this.searchString;
  }

  set getSearchString(value) {
    this.searchString = value;
  }

  changeChosenYear(event: any) {
    this.getChosenYear = event.target.value;
    this.statsCustomers = 0;
    this.statsExpectedInvoiced = 0;
    this.statsInvoices = 0;
    this.statsInvoicesMedian = 0;
    this.statsInvoicesTotal = 0;
    this.statsKreditnotas = 0;
    this.statsKreditnotasMedian = 0;
    this.statsKreditnotasTotal = 0;
    this.loadInvoiceDrafts();
  }

  changeChosenMonth(event: any) {
    this.getChosenMonth = event.target.value;
    this.getCustomerInvoiceDrafts = null;
    this.statsCustomers = 0;
    this.statsExpectedInvoiced = 0;
    this.statsInvoices = 0;
    this.statsInvoicesMedian = 0;
    this.statsInvoicesTotal = 0;
    this.statsKreditnotas = 0;
    this.statsKreditnotasMedian = 0;
    this.statsKreditnotasTotal = 0;
    this.loadInvoiceDrafts();
  }

  /**
   * Initialise
   */
  created() {
    this.loadInvoiceDrafts();
    //  Initialise modal by query
    window.addEventListener("popstate", () => this.initializeModel(), false);
  }

  get buttonText() {
    return this.sendToEconomicsText;
  }

  set buttonText(value) {
    this.sendToEconomicsText = value;
  }

  private initializeModel() {
    const search: any = this.$route.query.search;

    if (search) {
      this.searchString = search;
    }
  }

  private formatCurrency(num: number) {
    const formatter = new Intl.NumberFormat("da", {
      style: "currency",
      currency: "DKK",
    });

    return formatter.format(num); // num.toFixed(2).toLocaleString('da') + ' kr';
  }

  private sendToEconomics() {
    if (
      confirm(
        "WARNING: Are you sure you want to send to e-conomics? This can not be undone."
      )
    ) {
      /* eslint-disable-next-line */
      const _this = this;
      this.buttonText = "Sending";
      new JobsApi(AuthInterceptor.Instance).sendDraftsToEconomics().subscribe(
        (response) => {
          this.buttonText = "Done sending";

          setTimeout(function () {
            _this.buttonText = "Send to e-conomics";
          }, 10000);
        },
        (error) => {
          this.buttonText = "Something went wrong :(";
          eventHub.$emit("httpError", error);
        }
      );
    }
  }

  private loadInvoiceDrafts() {
    if (this.getChosenYear && this.getChosenMonth) {
      new InvoiceDraftApi(AuthInterceptor.Instance)
        .getInvoiceDraftsInInvoicePeriod({
          month: this.getChosenMonth,
          year: this.getChosenYear,
        })
        .subscribe(
          (response) => {
            this.invoiceDraftsLoading = false;
            this.invoiceDraftsLoaded = true;
            this.getCustomerInvoiceDrafts = response;

            this.statsCustomers = response.length;

            for (let i = 0; i < this.getCustomerInvoiceDrafts.length; i++) {
              const customer = this.getCustomerInvoiceDrafts[i];

              for (let u = 0; u < customer.invoiceDrafts.length; u++) {
                if (customer.invoiceDrafts[u]) {
                  if (customer.invoiceDrafts[u].economicLayoutId) {
                    if (customer.invoiceDrafts[u].economicLayoutId == 19) {
                      if (
                        customer.invoiceDrafts[u].amountExpectedInvoiced !=
                        undefined
                      ) {
                        this.statsInvoices += 1;
                        this.statsInvoicesTotal +=
                          customer.invoiceDrafts[u]!.amountExpectedInvoiced!;

                        this.statsExpectedInvoiced +=
                          customer.invoiceDrafts[u]!.amountExpectedInvoiced!;
                      }
                    } else if (
                      customer.invoiceDrafts[u].economicLayoutId == 24
                    ) {
                      if (
                        customer.invoiceDrafts[u].amountExpectedInvoiced !=
                        undefined
                      ) {
                        this.statsKreditnotas += 1;
                        this.statsKreditnotasTotal +=
                          customer.invoiceDrafts[u]!.amountExpectedInvoiced!;
                      }
                    }
                  }
                }
              }

              if (this.statsExpectedInvoiced > 0) {
                this.statsExpectedInvoiced =
                  Math.round(this.statsExpectedInvoiced * 100) / 100;
              }

              if (this.statsKreditnotas > 0) {
                this.statsInvoicesMedian =
                  this.statsInvoicesTotal / this.statsInvoices;
                this.statsInvoicesMedian =
                  Math.round(this.statsInvoicesMedian * 100) / 100;
              }

              if (this.statsKreditnotas > 0) {
                this.statsKreditnotasMedian =
                  this.statsKreditnotasTotal / this.statsKreditnotas;
                this.statsKreditnotasTotal =
                  Math.round(this.statsKreditnotasTotal * 100) / 100;
              }
            }
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  downloadPayoutFile() {
    if (this.getChosenYear && this.getChosenMonth) {
      new PayoutApi(AuthInterceptor.Instance)
        .generatePayoutFile({
          month: this.getChosenMonth,
          year: this.getChosenYear,
        })
        .subscribe(
          (response) => {
            if (response.size == 0) {
              alert("No file found");
              return false;
            }

            const link = document.createElement("a");
            // create a blobURI pointing to our Blob
            link.href = URL.createObjectURL(response);
            link.download = `PayoutFile ${this.getChosenYear}-${this.getChosenMonth}.csv`;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);

            console.log(response);
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }
}
